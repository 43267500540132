@media only screen and (max-width: 480px) {
    .footer-container {
        align-items: center;
        text-align: center;
        padding: 40px 25px 20px 25px;
        .footer-club-info {
            margin-bottom: 45px;
            .logo-footer {
                margin-bottom: 20px;
                img {
                    width: 75px;
                }
            }
            .academy-name {
                margin-bottom: 10px;
            }
            .contact-info {
                display: flex;
                justify-content: center;
                .tel {
                    padding: 0px 15px;
                    border-right: 2px solid black;
                }
                .email {
                    padding: 0px 15px;
                }
            }
        }
        .develop-info {
            display: none;
        }

        .develop-info-mobile {
            padding: 30px 0px;
            color: #ffffff;
            .line-one {
                display: flex;
                justify-content: center;
                gap: 30px;
                margin-bottom: 18px;
            }
        }
    }
}

@media only screen and (min-width: 480px) {
    .footer-container {
        align-items: center;
        text-align: center;
        padding: 40px 25px 20px 25px;
        .footer-club-info {
            margin-bottom: 45px;
            .logo-footer {
                margin-bottom: 20px;
                img {
                    width: 75px;
                }
            }
            .academy-name {
                margin-bottom: 10px;
            }
            .contact-info {
                display: flex;
                justify-content: center;
                .tel {
                    padding: 0px 15px;
                    border-right: 2px solid black;
                }
                .email {
                    padding: 0px 15px;
                }
            }
        }
        .develop-info {
            display: none;
        }

        .develop-info-mobile {
            padding: 30px 0px;
            color: #ffffff;
            .line-one {
                display: flex;
                justify-content: center;
                gap: 30px;
                margin-bottom: 18px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .footer-container {
        align-items: center;
        text-align: center;
        padding: 80px 80px 40px 80px;
        .footer-club-info {
            margin-bottom: 70px;
            .logo-footer {
                margin-bottom: 20px;
                img {
                    width: 80px;
                }
            }
            .academy-name {
                margin-bottom: 10px;
            }
            .contact-info {
                display: flex;
                justify-content: center;
                .tel {
                    padding: 0px 15px;
                    border-right: 1px solid black;
                }
                .email {
                    padding: 0px 15px;
                }
            }
        }
        .develop-info {
            padding: 30px 0px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            gap: 40px;
        }
        .develop-info-mobile {
            display: none;
        }
    }
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

.anpc,
.tfb {
    a:hover {
        color: #ffffff !important;
    }
}
