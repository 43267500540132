@media only screen and (max-width: 480px) {
    .live-widget-container {
        margin-bottom: 0px;
        margin-top: 0px;
        padding-top: 100px;
        .live-widget-title {
            text-align: center;
            font-size: 35px;
            margin-bottom: 20px;
        }

        .live-widget-meniu {
            display: flex;
            justify-content: center;

            .live-widget-meniu-elem {
                cursor: pointer;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }

        .live-table {
            display: none;
        }

        .live-table-mobile {
            display: block;
            .live-match {
                width: 85%;
                margin: auto;
                padding: 4%;
                background-color: white;
                box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                margin-bottom: 20px;
                .group {
                    margin: auto;
                    font-size: 15px;
                    line-height: 25px;
                    text-align: center;
                    width: 80px;
                    border-radius: 30px;
                    margin-bottom: 20px;
                }
                .after-group {
                    width: fit-content;
                    line-height: 25px;
                    margin: auto;
                    font-size: 15px;
                    text-align: center;
                    border-radius: 30px;
                    margin-bottom: 20px;
                    padding: 0 15px;
                }
                .match-details {
                    display: flex;
                    justify-content: center;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    align-items: center;
                    .match-result {
                        width: 22%;
                        font-size: 30px;
                        display: flex;
                        justify-content: center;
                        color: #fb2445;
                        .live-icon-container {
                            width: 15px;
                            height: 15px;
                            font-size: 0;
                            background-color: red;
                            border: 0;
                            border-radius: 35px;
                            margin-right: 10px;
                            outline: none;
                            position: relative;
                            top: 12px;
                        }
                    }
                    .first-team,
                    .second-team {
                        width: 37%;
                        display: flex;
                        .logo-container {
                            margin: auto;
                            .team-logo {
                                width: 30px;
                                padding: 0 5px 0 5px;
                                height: auto;
                            }
                        }

                        .team-name {
                            font-size: 12px;
                        }
                    }
                    .first-team {
                        text-align: left;
                    }
                    .second-team {
                        text-align: right;
                    }
                }

                .match-info {
                    display: flex;
                    justify-content: space-evenly;
                    font-size: 14px;
                    .split-line {
                        border-left: 1px solid;
                        line-height: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 480px) {
    .live-widget-container {
        margin-bottom: 0px;
        padding-top: 100px;
        .live-widget-title {
            text-align: center;
            font-size: 32px;
            margin-bottom: 20px;
        }

        .live-widget-meniu {
            display: flex;
            justify-content: center;

            .live-widget-meniu-elem {
                cursor: pointer;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }

        .live-table {
            display: none;
        }

        .live-table-mobile {
            .live-match {
                width: 85%;
                margin: auto;
                padding: 4%;
                background-color: white;
                box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                margin-bottom: 20px;
                .group {
                    margin: auto;
                    font-size: 15px;
                    line-height: 25px;
                    text-align: center;
                    width: 80px;
                    border-radius: 30px;
                    margin-bottom: 20px;
                }
                .after-group {
                    width: fit-content;
                    margin: auto;
                    font-size: 15px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 30px;
                    margin-bottom: 20px;
                    padding: 0 15px;
                }
                .match-details {
                    display: flex;
                    justify-content: center;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    .match-result {
                        font-size: 35px;
                        display: flex;
                        justify-content: center;
                        color: #fb2445;
                        .live-icon-container {
                            width: 15px;
                            height: 15px;
                            font-size: 0;
                            background-color: red;
                            border: 0;
                            border-radius: 35px;
                            margin-right: 10px;
                            outline: none;
                            position: relative;
                            top: 12px;
                        }
                    }
                    .first-team,
                    .second-team {
                        display: flex;
                        justify-content: space-around;
                        width: 40%;
                        align-items: center;
                        .team-logo {
                            width: 40px;
                            padding: 0 10px 0 10px;
                            height: fit-content;
                            width: auto;
                            height: 50px;
                        }

                        .team-name {
                            font-size: 15px;
                        }
                    }
                    .first-team {
                        text-align: left;
                    }
                    .second-team {
                        text-align: right;
                    }
                }

                .match-info {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    margin-top: 20px;
                    text-align: center;
                    .category,
                    .time {
                        width: 15%;
                    }
                    .split-line {
                        border-left: 1px solid;
                        line-height: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .live-widget-container {
        margin-bottom: 60px;
        padding-top: 100px;
        .live-widget-title {
            text-align: center;
            font-size: 48px;
            margin-bottom: 20px;
        }

        .live-widget-meniu {
            display: flex;
            justify-content: center;

            .live-widget-meniu-elem {
                cursor: pointer;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }

        .live-table {
            display: none;
        }

        .live-table-mobile {
            .live-match {
                width: 70%;
                margin: auto;
                padding: 4%;
                background-color: white;
                box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                margin-bottom: 20px;
                .group {
                    margin: auto;
                    font-size: 18px;
                    line-height: 32px;
                    text-align: center;
                    width: 80px;
                    border-radius: 30px;
                    margin-bottom: 20px;
                }
                .after-group {
                    width: fit-content;
                    margin: auto;
                    font-size: 18px;
                    line-height: 32px;
                    text-align: center;
                    border-radius: 30px;
                    margin-bottom: 20px;
                    padding: 0 15px;
                }
                .match-details {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    align-items: initial;
                    .match-result {
                        font-size: 35px;
                        display: flex;
                        justify-content: center;
                        color: #fb2445;
                        .live-icon-container {
                            width: 15px;
                            height: 15px;
                            font-size: 0;
                            background-color: red;
                            border: 0;
                            border-radius: 35px;
                            margin-right: 10px;
                            outline: none;
                            position: relative;
                            top: 12px;
                        }
                    }
                    .first-team,
                    .second-team {
                        width: 40%;
                        display: flex;
                        align-items: initial;
                        .team-logo {
                            width: 40px;
                            padding: 0 10px 0 10px;
                            height: fit-content;
                            width: auto;
                            height: 50px;
                        }

                        .team-name {
                            font-size: 18px;
                            position: relative;
                            top: 15px;
                        }
                    }
                }

                .match-info {
                    display: flex;
                    justify-content: space-evenly;
                    font-size: 14px;
                    .category,
                    .time {
                        width: 15%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .live-widget-container {
        padding-top: 120px;
        margin-bottom: 0px;
        .live-widget-title {
            font-size: 55px;
        }
    }

    .live-widget-container {
        .live-widget-title {
            text-align: center;
        }

        .live-widget-meniu {
            display: flex;
            justify-content: center;

            .live-widget-meniu-elem {
                cursor: pointer;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }
        .live-table-mobile {
            display: none;
        }
        .live-table {
            width: 90%;
            margin: auto;
            margin-top: 40px;
            display: block;
            .live-table-top-row {
                display: flex;
                text-align: center;
                font-size: 18px;

                .col {
                    margin: 22px 0;
                }

                .day {
                    width: 15%;
                }

                .time {
                    width: 10%;
                }

                .location {
                    width: 25%;
                }

                .match {
                    width: 50%;
                }

                .group {
                    width: 10%;
                }
            }

            .live-table-matches {
                margin-top: 20px;

                .live-match {
                    display: flex;
                    text-align: center;
                    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                    margin-bottom: 20px;
                    background-color: white;

                    .col {
                        margin: 45px 0;
                        font-size: 20px;
                        height: 20px;
                    }

                    .day {
                        width: 15%;
                    }

                    .time {
                        width: 10%;
                    }

                    .location {
                        width: 25%;
                    }

                    .match {
                        width: 50%;
                        display: flex;
                        gap: 5%;
                        justify-content: center;
                        text-transform: uppercase;
                        .match-result {
                            font-size: 35px;
                            top: -10px;
                            position: relative;
                            color: #fb2445;
                            display: flex;
                            .live-icon-container {
                                width: 15px;
                                height: 15px;
                                font-size: 0;
                                background-color: red;
                                border: 0;
                                border-radius: 35px;
                                margin-right: 10px;
                                outline: none;
                                position: relative;
                                top: 12px;
                            }
                        }
                        .first-team,
                        .second-team {
                            display: flex;
                            width: 44%;
                            .team-name {
                                font-size: 15px;
                            }
                            .logo-container {
                                .team-logo {
                                    width: 40px;
                                    position: relative;
                                    top: -13px;
                                    padding: 0 15px;
                                    height: fit-content;
                                    width: auto;
                                    height: 50px;
                                }
                            }
                        }
                        .first-team {
                            justify-content: space-between;
                        }
                        .second-team {
                            justify-content: space-between;
                        }
                    }

                    .group {
                        width: 10%;
                        border-radius: 100px;
                        text-align: center;
                        margin: 30px 0;
                        .group-circle {
                            border-radius: 50px;
                            width: 40px;
                            height: 40px;
                            margin: auto;
                            position: relative;
                            top: 6px;
                            .group-letter {
                                margin: auto;
                                text-align: center;
                                padding-top: 7px;
                            }
                        }
                    }
                    .after-group {
                        width: 10%;
                        margin: auto;
                        height: fit-content;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .live-widget-container {
        .live-widget-title {
            font-size: 65px;
            margin-bottom: 40px;
        }

        .live-widget-meniu {
            gap: 30px;

            .live-widget-meniu-elem {
                font-size: 35px;
            }
        }
    }

    .live-widget-container {
        .live-widget-title {
            text-align: center;
        }

        .live-widget-meniu {
            display: flex;
            justify-content: center;

            .live-widget-meniu-elem {
                cursor: pointer;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }
        .live-table-mobile {
            display: none;
        }
        .live-table {
            width: 90%;
            margin: auto;
            margin-top: 40px;
            .live-table-top-row {
                display: flex;
                text-align: center;
                font-size: 18px;

                .col {
                    margin: 22px 0;
                }

                .day {
                    width: 15%;
                }

                .time {
                    width: 10%;
                }

                .location {
                    width: 25%;
                }

                .match {
                    width: 50%;
                }

                .group {
                    width: 10%;
                }
            }

            .live-table-matches {
                margin-top: 20px;

                .live-match {
                    display: flex;
                    text-align: center;
                    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                    margin-bottom: 20px;
                    background-color: white;

                    .col {
                        margin: 45px 0;
                        font-size: 20px;
                        height: 20px;
                    }

                    .day {
                        width: 15%;
                    }

                    .time {
                        width: 10%;
                    }

                    .location {
                        width: 25%;
                    }

                    .match {
                        display: flex;
                        gap: 5%;
                        justify-content: center;
                        text-transform: uppercase;
                        .match-result {
                            font-size: 35px;
                            top: -10px;
                            position: relative;
                            color: #fb2445;
                            .live-icon-container {
                                width: 15px;
                                height: 15px;
                                font-size: 0;
                                background-color: red;
                                border: 0;
                                border-radius: 35px;
                                margin-right: 10px;
                                outline: none;
                                position: relative;
                                top: 12px;
                            }
                        }
                        .first-team,
                        .second-team {
                            display: flex;
                            width: 44%;
                            .team-name {
                                position: relative;
                                top: 0px;
                                font-size: 17px;
                            }
                            .logo-container {
                                .team-logo {
                                    width: 40px;
                                    position: relative;
                                    top: -13px;
                                    padding: 0 15px;
                                    height: fit-content;
                                    width: auto;
                                    height: 50px;
                                }
                            }
                        }
                        .first-team {
                            justify-content: space-between;
                        }
                        .second-team {
                            justify-content: space-between;
                        }
                    }

                    .group {
                        width: 10%;
                        border-radius: 100px;
                        text-align: center;
                        margin: 30px 0;
                        .group-circle {
                            border-radius: 50px;
                            width: 40px;
                            height: 40px;
                            margin: auto;
                            position: relative;
                            top: 6px;
                            .group-letter {
                                margin: auto;
                                text-align: center;
                                padding-top: 7px;
                            }
                        }
                    }
                    .after-group {
                        width: 10%;
                        margin: auto;
                        height: fit-content;
                    }
                }
            }
        }
    }
}

.rec {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation: 1.5s infinite linear pulse;
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
    }
    65% {
        box-shadow: 0px 0px 5px 8px rgba(173, 0, 0, 0.3);
    }
    90% {
        box-shadow: 0px 0px 5px 8px rgba(173, 0, 0, 0);
    }
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
    }
    65% {
        box-shadow: 0px 0px 5px 8px rgba(173, 0, 0, 0.3);
    }
    90% {
        box-shadow: 0px 0px 5px 8px rgba(173, 0, 0, 0);
    }
}
