.partners-container {
    width: 80%;
    margin: auto;
    .title-container {
        text-align: center;
    }
    .partners-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media only screen and (max-width: 480px) {
    .partners-container {
        width: 80%;
        margin: auto;
        margin-bottom: 40px;
        .title-container {
            margin-top: 40px;
            font-size: 32px;
            margin-bottom: 25px;
            text-align: center;
        }
        .partners-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .partner-container {
                padding: 15px;
                margin-bottom: 15px;
                img {
                    width: auto;
                    height: 70px;
                }
            }
        }
    }
}

@media only screen and (min-width: 480px) {
    .partners-container {
        width: 90%;
        margin-bottom: 20px;
        .title-container {
            margin-top: 20px;
            font-size: 35px;
            margin-bottom: 30px;
            text-align: center;
        }
        .partners-list {
            .partner-container {
                padding: 15px;
                margin-bottom: 15px;
                img {
                    width: auto;
                    height: 80px;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .partners-container {
        margin-bottom: 30px;
        .title-container {
            margin-top: 30px;
            font-size: 48px;
            margin-bottom: 30px;
            text-align: center;
        }
        .partners-list {
            .partner-container {
                padding: 20px;
                margin-bottom: 15px;
                img {
                    width: auto;
                    height: 100px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .partners-container {
        margin-bottom: 40px;
        .title-container {
            margin-top: 40px;
            font-size: 55px;
            margin-bottom: 35px;
            text-align: center;
        }
        .partners-list {
            .partner-container {
                padding: 20px;
                margin-bottom: 15px;
                img {
                    width: auto;
                    height: 130px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .partners-container {
        padding-top: 70px;

        .title-container {
            font-size: 65px;
            margin-bottom: 60px;
            text-align: center;
        }
        .partners-list {
            display: flex;
            justify-content: center;
            .partner-container {
                padding: 40px;
            }
        }
    }
}
