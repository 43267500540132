@media only screen and (min-width: 1200px) {
  .custom-button {
    font-family: "Helvetica Neue";
    height: 65px;
    padding-left: 45px;
    padding-right: 45px;
    font-size: 16px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1200px) {
  .custom-button {
    font-family: "Helvetica Neue";
    height: 50px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 10px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
