$main-color: #fbeb24;

.menu-option-text {
    color: white;
}

@media only screen and (max-width: 479px) {
    .header-container {
        font-family: "Helvetica Neue";
        height: 0px;
        .menu-header {
            height: 40px;
            display: inherit;
            position: absolute;
            top: 35px;
            width: 85%;
            right: 7.5%;
            margin: auto;
            .badge-name {
                padding-left: 15px;
                display: flex;
                align-items: center;
            }

            .menu-options {
                display: none;
            }

            .menu-mobile {
                align-self: center;
                display: flex;
                justify-content: space-between;

                .mobile-logo {
                    // width: 70px;
                    z-index: 33333;
                    position: relative;
                    top: -20px;
                    right: -2.5%;
                    height: 80px;
                    width: auto;
                }

                .menu-mobile-img {
                    width: 15px;
                    height: 15px;
                    right: 7.5%;
                    position: relative;
                    top: 10px;
                }
            }
        }

        .menu-header-on-scroll {
            height: 40px;
            display: inherit;
            position: absolute;
            top: 20px;
            width: 95%;
            right: 2.5%;
            margin: auto;
            .menu-options {
                max-height: 55px;
                min-height: 50px;
                .badge-name {
                    .mobile-logo {
                        width: 100px;
                        position: relative;
                        top: 5px;
                    }
                }
            }
        }

        .mobile-logo {
            width: 120px;
            z-index: 33333;
        }
    }

    .header-container-on-scroll {
        height: 75px;
        display: inherit;
        top: 0px;
        width: 100%;
        margin: auto;
        position: fixed;
        z-index: 33333;
    }

    .menu-mobile-content {
        padding-top: 50px;
        padding-bottom: 20px;
        width: 85%;
        margin: 35px 7.5%;
        font-size: 12px;
        position: absolute;
        top: 0px;
        z-index: 3333;
        color: white;
        text-decoration: none;

        .mobile-menu-option {
            padding-top: 20px;
            align-items: center;
            font-size: 16px;
            width: 80%;
            margin: auto;
            .option-content {
                display: flex;
                justify-content: space-between;
                padding-bottom: 18px;
            }

            .menu-mobile-goto {
                width: 10px;
                height: 10px;
                position: relative;
                top: 15px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .header-container {
        font-family: "Helvetica Neue";
        height: 0px;
        .menu-header {
            height: 40px;
            display: inherit;
            position: absolute;
            top: 35px;
            width: 85%;
            right: 7.5%;
            margin: auto;
            .badge-name {
                padding-left: 15px;
                display: flex;
                align-items: center;
            }

            .menu-options {
                display: none;
            }

            .menu-mobile {
                align-self: center;
                display: flex;
                justify-content: space-between;

                .mobile-logo {
                    z-index: 33333;
                    position: relative;
                    top: -20px;
                    right: -2.5%;
                    height: 80px;
                    width: auto;
                }

                .menu-mobile-img {
                    width: 15px;
                    height: 15px;
                    right: 7.5%;
                    position: relative;
                    top: 13px;
                }
            }
        }

        .menu-header-on-scroll {
            height: 40px;
            display: inherit;
            position: absolute;
            top: 20px;
            width: 95%;
            right: 2.5%;
            margin: auto;
            .menu-options {
                max-height: 55px;
                min-height: 50px;
                .badge-name {
                    .mobile-logo {
                        width: 100px;
                        position: relative;
                        top: 5px;
                    }
                }
            }
        }

        .mobile-logo {
            width: 120px;
            z-index: 33333;
        }
    }

    .header-container-on-scroll {
        height: 75px;
        display: inherit;
        top: 5px;
        width: 100%;
        margin: auto;
        position: fixed;
        z-index: 33333;
    }

    .menu-mobile-content {
        padding-top: 50px;
        padding-bottom: 20px;
        width: 85%;
        margin: 35px 7.5%;
        font-size: 12px;
        position: absolute;
        top: 0px;
        z-index: 3333;
        color: white;
        text-decoration: none;

        .mobile-menu-option {
            padding-top: 20px;
            align-items: center;
            font-size: 16px;
            width: 80%;
            margin: auto;
            .option-content {
                display: flex;
                justify-content: space-between;
                padding-bottom: 18px;
            }

            .menu-mobile-goto {
                width: 10px;
                height: 10px;
                position: relative;
                top: 15px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .header-container {
        font-family: "Helvetica Neue";
        height: 0px;
        .menu-header {
            height: 55px;
            display: inherit;
            position: absolute;
            top: 35px;
            width: 85%;
            right: 7.5%;
            margin: auto;
            .badge-name {
                padding-left: 15px;
                display: flex;
                align-items: center;
            }

            .menu-options {
                display: none;
            }

            .menu-mobile {
                align-self: center;
                display: flex;
                justify-content: space-between;

                .mobile-logo {
                    z-index: 33333;
                    position: relative;
                    top: -22.5px;
                    right: -2.5%;
                    height: 110px;
                    width: auto;
                }

                .menu-mobile-img {
                    width: 18px;
                    height: 18px;
                    right: calc(15% / 2);
                    position: relative;
                    top: 18px;
                }
            }
        }

        .menu-header-on-scroll {
            height: 55px;
            display: inherit;
            position: absolute;
            top: 20px;
            width: 95%;
            right: 2.5%;
            margin: auto;
            .menu-options {
                max-height: 55px;
                min-height: 50px;
                .badge-name {
                    .mobile-logo {
                        width: 100px;
                        position: relative;
                        top: 5px;
                    }
                }
            }
        }
    }

    .header-container-on-scroll {
        height: 75px;
        display: inherit;
        top: 10px;
        width: 100%;
        margin: auto;
        position: fixed;
        z-index: 33333;
    }

    .menu-mobile-content {
        padding-top: 80px;
        padding-bottom: 40px;
        width: 90%;
        margin: 35px 5%;
        font-size: 12px;
        position: absolute;
        top: 0px;
        z-index: 3333;
        color: white;
        text-decoration: none;

        .mobile-menu-option {
            padding-top: 20px;
            align-items: center;
            font-size: 16px;
            width: 80%;
            margin: auto;
            .option-content {
                display: flex;
                justify-content: space-between;
                padding-bottom: 18px;
            }

            .menu-mobile-goto {
                width: 10px;
                height: 10px;
                position: relative;
                top: 15px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .header-container {
        font-family: "Helvetica Neue";
        .menu-header {
            height: 0px;
            position: absolute;
            top: 35px;
            width: 85%;
            right: 7.5%;
            margin: auto;

            .menu-mobile {
                display: none;
            }
            .menu-options {
                display: flex;
                align-items: center;
                justify-content: center;

                .badge-name {
                    padding: 0px 35px;
                    display: flex;
                    align-items: center;

                    .mobile-logo {
                        height: 120px;
                        width: auto;
                    }
                }
                .menu-option {
                    align-self: center;
                    padding-left: 35px;
                    padding-right: 35px;
                    font-size: 16px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .arrow-down-menu {
                        height: 10px;
                        width: 10px;
                        margin-left: 10px;
                    }

                    img {
                        height: 10px;
                        padding-left: 20px;
                    }
                }

                .active-menu-option {
                    background-color: #000000 !important;
                    color: $main-color !important;
                }
            }
        }
        .menu-header-on-scroll {
            height: 55px;
            display: inherit;
            position: absolute;
            top: 20px;
            width: 95%;
            right: 2.5%;
            margin: auto;
            .menu-options {
                max-height: 55px;
                min-height: 50px;
                .badge-name {
                    .mobile-logo {
                        position: relative;
                        top: 15px;
                        height: 120px;
                        width: auto;
                    }
                }
            }
        }
    }
    .header-container-on-scroll {
        height: 75px;
        display: inherit;
        top: 0px;
        width: 100%;
        margin: auto;
        position: fixed;
        z-index: 33333;
    }

    .menu-mobile-content {
        display: none;
    }
}

@media only screen and (min-width: 1280px) {
    .header-container {
        font-family: "Helvetica Neue";
        background-color: transparent;
        .menu-header {
            height: 0px;
            position: absolute;
            top: 35px;
            width: 85%;
            right: 7.5%;
            margin: auto;
            background-color: transparent;

            .menu-options {
                display: flex;
                align-items: center;
                justify-content: center;

                .badge-name {
                    padding: 0px 50px;
                    display: flex;
                    align-items: center;
                }
                .menu-option {
                    align-self: center;
                    padding-left: 50px;
                    padding-right: 50px;
                    font-size: 16px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .arrow-down-menu {
                        height: 10px;
                        width: 10px;
                        margin-left: 10px;
                    }

                    img {
                        height: 10px;
                        padding-left: 20px;
                    }
                }

                .active-menu-option {
                    background-color: #000000 !important;
                    color: $main-color !important;
                }
            }
        }
        .menu-header-on-scroll {
            height: 55px;
            display: inherit;
            position: absolute;
            top: 20px;
            width: 95%;
            right: 2.5%;
            margin: auto;
            .menu-options {
                max-height: 55px;
                min-height: 50px;
            }
        }
    }
    .header-container-on-scroll {
        height: 75px;
        display: inherit;
        top: 0px;
        width: 100%;
        margin: auto;
        position: fixed;
        z-index: 33333;
    }
}
