.about-competition-container {
    text-align: center;

    .about-content {
        font-family: "Roboto";
    }

    .about-read-more {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 480px) {
    .about-competition-container {
        padding: 40px 40px 0 40px;

        .about-title {
            font-size: 32px;
            font-weight: 700;
        }

        .about-content {
            margin: 25px 0;
            font-size: 15px;
            line-height: 22px;
        }
    }
}

@media only screen and (min-width: 480px) {
    .about-competition-container {
        padding: 40px 10% 0 10%;

        .about-title {
            font-size: 35px;
            font-weight: 700;
        }

        .about-content {
            margin: 30px 0;
            font-size: 16px;
            line-height: 25px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .about-competition-container {
        padding: 60px 15% 0 15%;

        .about-title {
            font-size: 48px;
            font-weight: 700;
        }

        .about-content {
            margin: 35px 0;
            font-size: 17px;
            line-height: 28px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .about-competition-container {
        padding: 80px 20% 0 20%;

        .about-title {
            font-size: 55px;
            font-weight: 700;
        }

        .about-content {
            margin: 40px 0;
            font-size: 18px;
            line-height: 31px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .about-competition-container {
        padding: 100px 25% 0 25%;

        .about-title {
            font-size: 65px;
            font-weight: 700;
        }

        .about-content {
            margin: 45px 0;
            font-size: 19px;
            line-height: 33px;
        }
    }
}
