.teams-widget-container {
    .teams-widget-title {
        text-align: center;
    }

    .teams-widget-meniu {
        display: flex;
        justify-content: center;

        .teams-widget-meniu-elem {
            cursor: pointer;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
    }
}

@media only screen and (max-width: 480px) {
    .teams-widget-container {
        padding-top: 80px;
        .teams-widget-title {
            font-size: 35px;
            margin-bottom: 20px;
        }

        .teams-widget-meniu {
            display: flex;
            justify-content: center;
            gap: 22px;

            .teams-widget-meniu-elem {
                font-size: 16px;
            }
        }

        .teams-widget-teams {
            margin-top: 20px;
            .carousel-container {
                padding: 0 30px;
            }
        }
    }

    .groups-homepage-carousel-buttons {
        display: flex;
        justify-content: center;
        gap: 20px;

        .prev-button-desktop-teams {
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .prev-button-img {
                height: 15px;
            }
        }

        .next-button-desktop-teams {
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .next-button-img {
                height: 15px;
            }
        }
    }

    .team-elem-container {
        height: 135px;
        width: 110px;
        padding: 15px;
        box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 3%);
        background-color: white;
        margin-bottom: 30px;

        .team-logo {
            img {
                width: 50px;
            }
        }

        .team-name {
            font-size: 16px;
            text-align: center;
            margin-top: 10px;
        }
    }
}

@media only screen and (min-width: 480px) {
    .teams-widget-container {
        padding-top: 80px;

        .teams-widget-title {
            font-size: 35px;
            margin-bottom: 25px;
        }

        .teams-widget-meniu {
            display: flex;
            justify-content: center;
            gap: 24px;

            .teams-widget-meniu-elem {
                font-size: 20px;
            }
        }
        .teams-widget-teams {
            .carousel-container {
                padding: 0 15%;
            }
        }
    }
    .groups-homepage-carousel-buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        left: 150px;
        right: 150px;

        .prev-button-desktop-teams {
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .prev-button-img {
                height: 15px;
            }
        }

        .next-button-desktop-teams {
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .next-button-img {
                height: 15px;
            }
        }
    }

    .team-elem-container {
        height: 135px;
        width: 110px;
        padding: 15px;
        box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 3%);
        background-color: white;
        margin-bottom: 30px;

        .team-logo {
            img {
                width: 50px;
            }
        }

        .team-name {
            font-size: 16px;
            text-align: center;
            margin-top: 10px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .teams-widget-container {
        padding-top: 100px;

        .teams-widget-title {
            font-size: 48px;
            margin-bottom: 30px;
        }

        .teams-widget-meniu {
            display: flex;
            justify-content: center;
            gap: 26px;

            .teams-widget-meniu-elem {
                font-size: 24px;
            }
        }
        .teams-widget-teams {
            margin-top: 20px;
            .carousel-container {
                padding: 0 15%;
            }
        }
    }
    .groups-homepage-carousel-buttons {
        left: 0;
        right: 0;
    }
}

@media only screen and (min-width: 1024px) {
    .teams-widget-container {
        padding-top: 90px;

        .teams-widget-title {
            font-size: 55px;
            margin-bottom: 35px;
        }

        .teams-widget-meniu {
            display: flex;
            justify-content: center;
            gap: 28px;

            .teams-widget-meniu-elem {
                font-size: 30px;
            }
        }
        .teams-widget-teams {
            margin-top: 30px;
            .carousel-container {
                padding: 0 13%;
            }
        }
    }
    .groups-homepage-carousel-buttons {
        display: flex;
        justify-content: space-between;
        bottom: 170px;
        position: relative;
        width: 95%;
        margin: auto;

        .prev-button-desktop-teams {
            height: 75px;
            width: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .prev-button-img {
                height: 15px;
            }
        }

        .next-button-desktop-teams {
            height: 75px;
            width: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .next-button-img {
                height: 15px;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .teams-widget-container {
        padding-top: 100px;

        .teams-widget-title {
            font-size: 65px;
            margin-bottom: 40px;
        }

        .teams-widget-meniu {
            gap: 30px;

            .teams-widget-meniu-elem {
                font-size: 35px;
            }
        }

        .teams-widget-teams {
            margin-top: 40px;
            .carousel-container {
                padding: 0 150px;
            }
        }
    }

    .groups-homepage-carousel-buttons {
        display: flex;
        justify-content: space-between;
        bottom: 200px;
        position: relative;
        width: 95%;
        margin: auto;

        .prev-button-desktop-teams {
            height: 75px;
            width: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .prev-button-img {
                height: 15px;
            }
        }

        .next-button-desktop-teams {
            height: 75px;
            width: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .next-button-img {
                height: 15px;
            }
        }
    }
}
