@media only screen and (max-width: 480px) {
    .special-players-container {
        width: 90%;
        margin: auto;
        padding-top: 15px;
    }

    .card-container {
        width: calc(90% - 30px);
        margin-bottom: 15px;
        padding: 30px 30px 0px 30px;
        border-radius: 30px;
        background-color: white;
        box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
        .card-content {
            display: flex;

            .category {
                width: 30%;
                text-align: center;
                border-right: 1px solid #f2f2f2;
                padding-right: 20px;

                .category-logo {
                    margin: 10px 0px;

                    img {
                        width: 40px;
                    }
                }
                .category-title {
                    margin-bottom: 15px;
                    margin-top: 0px;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .player {
                width: 50%;
                text-align: center;
                margin: auto;
                font-size: 20px;
                padding-left: 30px;
                .player-logo {
                    img {
                        width: 65px;
                        border-radius: 40px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .card-number {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 20px;
            height: 20px;
            width: 30%;
            text-align: center;
        }
        .card-number-border {
            width: 30%;
            display: flex;
            justify-content: center;
            .border {
                width: 70px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 480px) {
    .special-players-container {
        width: 80%;
        margin: auto;
        padding-top: 15px;
    }

    .card-container {
        width: calc(80% - 30px);
        padding: 30px 30px 0px 30px;
        border-radius: 30px;
        background-color: white;
        box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
        margin: auto;
        margin-bottom: 15px;
        .card-content {
            display: flex;

            .category {
                width: 30%;
                text-align: center;
                border-right: 1px solid #f2f2f2;
                padding-right: 20px;

                .category-logo {
                    margin: 10px 0px;

                    img {
                        width: 40px;
                    }
                }
                .category-title {
                    margin-bottom: 15px;
                    margin-top: 0px;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .player {
                width: 50%;
                text-align: center;
                margin: auto;
                font-size: 20px;
                padding-left: 30px;
                .player-logo {
                    img {
                        width: 65px;
                        border-radius: 40px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .card-number {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 20px;
            height: 20px;
            width: 30%;
            text-align: center;
        }
        .card-number-border {
            width: 30%;
            display: flex;
            justify-content: center;
            .border {
                width: 70px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .special-players-container {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
    }

    .card-container {
        width: 25.5%;
        padding: 25px 25px 0px 25px;
        border-radius: 30px;
        background-color: white;
        box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);

        .card-content {
            display: flex;

            .category {
                width: 30%;
                text-align: center;
                border-right: 1px solid #f2f2f2;
                padding-right: 22px;
                position: relative;
                top: 13px;

                .category-logo {
                    margin: 10px 0px;

                    img {
                        width: 40px;
                        height: 40px;
                        inline-size: fit-content;
                    }
                }
                .category-title {
                    margin-bottom: 20px;
                    margin-top: 10px;
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 20px;
                }
            }
            .player {
                width: 50%;
                text-align: center;
                margin: auto;
                font-size: 18px;
                padding-left: 15px;
                .player-logo {
                    img {
                        width: 60px;
                        border-radius: 40px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
        .card-number {
            margin-bottom: 5px;
            font-size: 18px;
            line-height: 20px;
            height: 20px;
            width: 30%;
            text-align: center;
        }
        .card-number-border {
            width: 30%;
            display: flex;
            justify-content: center;
            .border {
                width: 70px;
                border-bottom: 10px solid black;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .special-players-container {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding-top: 25px;
    }

    .card-container {
        width: 27%;
        padding: 25px 25px 0px 25px;
        border-radius: 30px;
        background-color: white;
        box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);

        .card-content {
            display: flex;

            .category {
                width: 30%;
                text-align: center;
                border-right: 1px solid rgb(242 242 242);
                padding-right: 20px;

                .category-logo {
                    margin: 30px 0px;
                }
                .category-title {
                    margin-bottom: 20px;
                    margin-top: 10px;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .player {
                width: 50%;
                text-align: center;
                margin: auto;
                font-size: 25px;
                line-height: 30px;
                padding-left: 20px;
                .player-logo {
                    img {
                        width: 80px;
                        border-radius: 40px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .card-number {
            margin-bottom: 15px;
            font-size: 25px;
            line-height: 20px;
            height: 25px;
            width: 30%;
            text-align: center;
        }
        .card-number-border {
            width: 30%;
            display: flex;
            justify-content: center;
            .border {
                width: 70px;
                border-bottom: 10px solid black;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .special-players-container {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
    }

    .card-container {
        width: 27%;
        padding: 30px 30px 0px 30px;
        border-radius: 30px;
        background-color: white;
        box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);

        .card-content {
            display: flex;

            .category {
                width: 30%;
                text-align: center;
                border-right: 1px solid rgb(242 242 242);
                padding-right: 30px;

                .category-logo {
                    margin: 30px 0px;
                }
                .category-title {
                    margin-bottom: 25px;
                    margin-top: 10px;
                    text-transform: uppercase;
                    font-size: 20px;
                    line-height: 20px;
                }
            }
            .player {
                width: 50%;
                text-align: center;
                margin: auto;
                font-size: 30px;
                line-height: 30px;
                padding-left: 30px;
                .player-logo {
                    img {
                        width: 80px;
                        border-radius: 40px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .card-number {
            margin-bottom: 20px;
            font-size: 30px;
            line-height: 20px;
            height: 20px;
            width: 30%;
            text-align: center;
        }
        .card-number-border {
            width: 30%;
            display: flex;
            justify-content: center;
            .border {
                width: 70px;
                border-bottom: 10px solid black;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
    }
}
