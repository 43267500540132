.group-ranking-widget-container {
    margin-bottom: 40px;

    .no-groups-content {
        text-align: center;
    }
    .group-ranking-widget-title {
        text-align: center;
    }

    .group-ranking-widget-meniu {
        display: flex;
        justify-content: center;

        .group-ranking-widget-meniu-elem {
            cursor: pointer;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
    }

    .group-ranking-container {
        .group-name {
            text-transform: uppercase;
            text-align: center;
        }

        .group-ranking-table {
            width: 90%;
            margin: auto;
            margin-top: 40px;
            box-shadow: 0px 20px 30px 20px rgb(0 0 0 / 3%);

            .group-ranking-table-top-row {
                display: flex;
                text-align: center;
                font-size: 18px;

                .col {
                    margin: 22px 0;
                }

                .pos {
                    width: 8%;
                }

                .team {
                    width: 52%;
                }

                .nr-match {
                    width: 8%;
                }

                .nr-win {
                    width: 8%;
                }

                .nr-draw {
                    width: 8%;
                }

                .nr-loss {
                    width: 8%;
                }

                .goal-diff {
                    width: 8%;
                }

                .point {
                    width: 8%;
                }
            }
        }

        .group-ranking-lines {
            margin-top: 20px;
            margin-bottom: 50px;

            .ranking-line {
                display: flex;
                text-align: center;
                box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                margin-bottom: 20px;
                background-color: white;

                .col {
                    margin: 45px 0;
                    font-size: 20px;
                    height: 20px;
                }

                .pos {
                    width: 8%;
                    font-size: 35px;
                    margin-left: -10px;
                    .index-line {
                        position: relative;
                        top: -10px;
                    }
                }

                .team {
                    width: 52%;
                    display: flex;
                    text-transform: uppercase;
                    .team-name {
                        top: 0px;
                        position: relative;
                    }
                }

                .nr-match {
                    width: 8%;
                }

                .nr-win {
                    width: 8%;
                }

                .nr-draw {
                    width: 8%;
                }

                .nr-loss {
                    width: 8%;
                }

                .goal-diff {
                    width: 8%;
                }

                .point {
                    width: 8%;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .group-ranking-widget-container {
        padding-top: 80px;
        .group-ranking-widget-title {
            font-size: 32px;
            margin-bottom: 20px;
        }

        .group-ranking-widget-meniu {
            gap: 22px;

            .group-ranking-widget-meniu-elem {
                font-size: 16px;
            }
        }

        .group-ranking-container {
            .group-name {
                font-size: 22px;
                margin: 20px 0px;
            }
            .group-ranking-table {
                margin-top: 0px;
                display: none;
            }
            .group-ranking-table-mobile {
                width: 90%;
                margin: auto;

                .group-ranking-table-top-row {
                    display: flex;
                    text-align: center;
                    font-size: 18px;

                    .col {
                        margin: 15px 0;
                    }

                    .team {
                        width: calc(38% - 20px);
                        text-align: left;
                        padding-left: 20px;
                    }

                    .nr-match {
                        width: 8%;
                    }

                    .nr-win {
                        width: 8%;
                    }

                    .nr-draw {
                        width: 8%;
                    }

                    .nr-loss {
                        width: 8%;
                    }

                    .goal-diff {
                        width: 15%;
                    }

                    .point {
                        width: 15%;
                    }
                }
            }

            .group-ranking-lines {
                margin-top: 20px;
                margin-bottom: 50px;

                .ranking-line {
                    display: flex;
                    text-align: center;
                    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                    margin-bottom: 15px;
                    background-color: white;
                    font-size: 12px;
                    .col {
                        margin: 25px 0;
                        font-size: 12px;
                        height: 20px;
                    }

                    .team {
                        width: 38%;
                        display: flex;
                        text-transform: uppercase;
                        .team-name {
                            top: 0px;
                            position: relative;
                            font-size: 12px;
                            line-height: 14px;
                            text-align: start;
                            margin-left: 5px;
                        }
                        .logo-container {
                            .team-logo {
                                width: 30px;
                                height: auto;
                                margin-left: 5px;
                            }
                        }
                    }

                    .nr-match {
                        width: 8%;
                    }

                    .nr-win {
                        width: 8%;
                    }

                    .nr-draw {
                        width: 8%;
                    }

                    .nr-loss {
                        width: 8%;
                    }

                    .goal-diff {
                        width: 15%;
                    }

                    .point {
                        width: 15%;
                    }
                }
            }
        }
    }

    .no-groups-content {
        font-size: 15px;
    }
}

@media only screen and (min-width: 480px) {
    .group-ranking-widget-container {
        padding-top: 80px;
        .group-ranking-widget-title {
            font-size: 35px;
            margin-bottom: 20px;
        }

        .group-ranking-widget-meniu {
            gap: 22px;

            .group-ranking-widget-meniu-elem {
                font-size: 20px;
            }
        }

        .group-ranking-container {
            .group-name {
                font-size: 22px;
                margin: 20px 0px;
            }
            .group-ranking-table {
                margin-top: 0px;
                display: none;
            }
            .group-ranking-table-mobile {
                width: 90%;
                margin: auto;

                .group-ranking-table-top-row {
                    display: flex;
                    text-align: center;
                    font-size: 18px;

                    .col {
                        margin: 15px 0;
                    }

                    .team {
                        width: calc(38% - 20px);
                        text-align: left;
                        padding-left: 20px;
                    }

                    .nr-match {
                        width: 8%;
                    }

                    .nr-win {
                        width: 8%;
                    }

                    .nr-draw {
                        width: 8%;
                    }

                    .nr-loss {
                        width: 8%;
                    }

                    .goal-diff {
                        width: 15%;
                    }

                    .point {
                        width: 15%;
                    }
                }
            }

            .group-ranking-lines {
                margin-top: 20px;
                margin-bottom: 50px;
                .ranking-line {
                    display: flex;
                    text-align: center;
                    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                    margin-bottom: 15px;
                    background-color: white;

                    .col {
                        margin: 25px 0;
                        font-size: 14px;
                        height: 20px;
                    }

                    .team {
                        width: 38%;
                        display: flex;
                        text-transform: uppercase;
                        .team-name {
                            top: 3px;
                            position: relative;
                            font-size: 14px;
                            line-height: 14px;
                            text-align: start;
                            margin-left: 10px;
                        }
                        .logo-container {
                            .team-logo {
                                width: 30px;
                                height: auto;
                                margin-left: 5px;
                            }
                        }
                    }

                    .nr-match {
                        width: 8%;
                    }

                    .nr-win {
                        width: 8%;
                    }

                    .nr-draw {
                        width: 8%;
                    }

                    .nr-loss {
                        width: 8%;
                    }

                    .goal-diff {
                        width: 15%;
                    }

                    .point {
                        width: 15%;
                    }
                }
            }
        }
    }

    .no-groups-content {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) {
    .group-ranking-widget-container {
        padding-top: 80px;
        .group-ranking-widget-title {
            font-size: 48px;
        }
        .group-ranking-widget-meniu {
            .group-ranking-widget-meniu-elem {
                font-size: 24px;
            }
        }
    }

    .no-groups-content {
        font-size: 17px;
    }
}

@media only screen and (min-width: 1024px) {
    .group-ranking-widget-container {
        padding-top: 100px;
        .group-ranking-widget-title {
            margin-bottom: 20px;
        }

        .group-ranking-widget-meniu {
            gap: 30px;

            .group-ranking-widget-meniu-elem {
                font-size: 30px;
            }
        }

        .group-ranking-container {
            .group-name {
                font-size: 25px;
                margin: 30px 0px 0px 0px;
            }
        }
        .group-ranking-table-mobile {
            display: none;
        }
    }

    .group-ranking-widget-container {
        margin-bottom: 0px;
        .group-ranking-widget-title {
            font-size: 55px;
            text-align: center;
        }

        .group-ranking-widget-meniu {
            display: flex;
            justify-content: center;

            .group-ranking-widget-meniu-elem {
                cursor: pointer;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }

        .group-ranking-container {
            .group-name {
                text-transform: uppercase;
                text-align: center;
            }

            .group-ranking-table {
                width: 90%;
                margin: auto;
                margin-top: 20px;
                box-shadow: 0px 20px 30px 20px rgb(0 0 0 / 3%);
                display: block;
                .group-ranking-table-top-row {
                    display: flex;
                    text-align: center;
                    font-size: 18px;

                    .col {
                        margin: 22px 0;
                    }

                    .pos {
                        width: 9%;
                    }

                    .team {
                        width: 43%;
                    }

                    .nr-match {
                        width: 9%;
                    }

                    .nr-win {
                        width: 9%;
                    }

                    .nr-draw {
                        width: 9%;
                    }

                    .nr-loss {
                        width: 10%;
                    }

                    .goal-diff {
                        width: 10%;
                    }

                    .point {
                        width: 9%;
                    }
                }
            }

            .group-ranking-lines {
                margin-top: 20px;
                margin-bottom: 0px;

                .ranking-line {
                    display: flex;
                    text-align: center;
                    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                    margin-bottom: 20px;
                    background-color: white;

                    .col {
                        margin: 45px 0;
                        font-size: 20px;
                        height: 20px;
                    }

                    .pos {
                        width: 9%;
                        font-size: 35px;
                        margin-left: -10px;
                        .index-line {
                            position: relative;
                            top: -10px;
                        }
                    }

                    .team {
                        width: 43%;
                        display: flex;
                        gap: 5%;
                        text-transform: uppercase;
                        .team-name {
                            font-size: 20px;
                            top: 5px;
                            position: relative;
                        }
                        .team-logo {
                            width: 80px;
                            display: flex;
                            justify-content: center;
                            position: relative;
                            top: -12px;
                            img {
                                width: auto;
                                height: 50px;
                            }
                        }
                    }

                    .nr-match {
                        width: 9%;
                    }

                    .nr-win {
                        width: 9%;
                    }

                    .nr-draw {
                        width: 9%;
                    }

                    .nr-loss {
                        width: 10%;
                    }

                    .goal-diff {
                        width: 10%;
                    }

                    .point {
                        width: 9%;
                    }
                }
            }
        }
    }

    .no-groups-content {
        font-size: 18px;
    }
}

@media only screen and (min-width: 1280px) {
    .group-ranking-widget-container {
        padding-top: 100px;
        .group-ranking-widget-title {
            font-size: 65px;
        }
        .group-ranking-widget-meniu {
            .group-ranking-widget-meniu-elem {
                font-size: 35px;
            }
        }
        .group-ranking-container {
            .group-name {
                text-transform: uppercase;
                text-align: center;
                font-size: 30px;
            }
        }
    }

    .group-ranking-widget-meniu-elem {
        font-size: 35px;
    }

    .no-groups-content {
        font-size: 19px;
    }
}
