.rules-content {
    font-family: "Roboto";
}
@media only screen and (max-width: 480px) {
    .rules-container {
        padding: 80px 40px 0 40px;

        .rules-title {
            font-size: 32px;
            margin-bottom: 25px;
            text-align: center;
        }

        .rules-content {
            overflow: hidden;
            font-size: 15px;
            line-height: 23px;
            text-align: justify;
            overflow-wrap: break-word;
            word-wrap: break-word;
        }
    }
}

@media only screen and (min-width: 480px) {
    .rules-container {
        width: 80%;
        margin: auto;
        padding-bottom: 40px;
        padding-top: 80px;

        .rules-title {
            font-size: 35px;
            margin-bottom: 30px;
            text-align: center;
        }

        .rules-content {
            font-size: 16px;
            line-height: 30px;
            overflow: hidden;
            text-align: justify;
            overflow-wrap: break-word;
            word-wrap: break-word;
        }
    }
}

@media only screen and (min-width: 768px) {
    .rules-container {
        width: 70%;
        margin: auto;
        padding-top: 100px;
        .rules-title {
            font-size: 48px;
            margin-bottom: 40px;
            text-align: center;
        }

        .rules-content {
            font-size: 17px;
            line-height: 30px;
            overflow: hidden;
            text-align: justify;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .rules-container {
        width: 60%;
        margin: auto;
        padding-bottom: 60px;
        padding-top: 90px;
        .rules-title {
            font-size: 55px;
            margin-bottom: 40px;
            text-align: center;
        }

        .rules-content {
            font-size: 18px;
            line-height: 30px;
            overflow: hidden;
            text-align: justify;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .rules-container {
        width: 50%;
        padding-bottom: 0px;
        padding-top: 100px;
        .rules-title {
            font-size: 65px;
            margin-bottom: 60px;
            text-align: center;
        }

        .rules-content {
            font-size: 20px;
            line-height: 35px;
            overflow: hidden;
            text-align: justify;
        }
    }
}
