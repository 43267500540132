@media only screen and (max-width: 480px) {
    .team-elem-container {
        height: 110px;
        width: 110px;
        padding: 20px 15px;
        box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 3%);
        background-color: white;
        margin-bottom: 30px;

        .team-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 55px;

            img {
                height: auto;
                width: 50px;
            }
        }

        .team-name {
            font-size: 16px;
            text-align: center;
            margin-top: 10px;
            text-transform: uppercase;
        }
    }
}

@media only screen and (min-width: 480px) {
    .team-elem-container {
        height: 130px;
        width: 110px;
        padding: 20px 15px;
        box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 3%);
        background-color: white;
        margin-bottom: 30px;

        .team-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 65px;

            img {
                height: fit-content;
                width: 50px;
            }
        }

        .team-name {
            font-size: 16px;
            text-align: center;
            margin-top: 10px;
            text-transform: uppercase;
        }
    }
}

@media only screen and (min-width: 768px) {
    .team-elem-container {
        height: 130px;
        width: 110px;
        padding: 20px 15px;
        box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 3%);
        background-color: white;
        margin-bottom: 30px;

        .team-logo {
            display: flex;
            justify-content: center;
            height: 65px;

            img {
                width: 50px;
            }
        }

        .team-name {
            font-size: 16px;
            text-align: center;
            margin-top: 10px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .team-elem-container {
        height: 160px;
        width: 150px;
        padding: 20px 15px;
        box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 3%);
        background-color: white;
        margin-bottom: 30px;

        .team-logo {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;

            img {
                width: 50px;
                height: auto;
            }
        }

        .team-name {
            font-size: 18px;
            text-align: center;
            margin-top: 10px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .team-elem-container {
        height: 200px;
        width: 200px;
        padding: 30px;
        box-shadow: 0px 25px 30px 0px rgb(0 0 0 / 3%);
        background-color: white;
        margin-bottom: 50px;
        .team-logo {
            display: flex;
            height: 100px;
            justify-content: center;

            .team-logo-img {
                width: 80px;
            }
        }

        .team-name {
            font-size: 20px;
            text-align: center;
            line-height: 23px;
            padding: 20px 30px 0 30px;
            margin-top: 0px;
        }
    }
}
