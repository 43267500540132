$main-color: #fbeb24;

.container-homepage {
    font-family: "Helvetica Neue";
    position: relative;
    .img-background {
        width: 100%;
        object-fit: cover;
        filter: brightness(0.4);
    }
    .intro-content-container {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .title {
            text-transform: uppercase;
            color: white;
        }

        .description {
            color: white;
        }
    }
}

.button-row {
    text-align: -webkit-center;
}

.discover-more-button-icon {
    width: 12px !important;
    height: 12px !important;
    margin-left: 10px;
}

@media only screen and (max-width: 480px) {
    .container-homepage {
        .img-background {
            height: 500px;
        }
        .intro-content-container {
            top: calc(50% + 45px);
            width: 75%;

            .title {
                font-size: 50px;
                line-height: 50px;
                padding-bottom: 30px;
            }

            .description {
                font-size: 20px;
                line-height: 25px;
                padding-bottom: 30px;
            }
        }
    }
}

@media only screen and (min-width: 480px) {
    .container-homepage {
        .img-background {
            height: 550px;
        }
        .intro-content-container {
            top: 48%;
            width: 75%;

            .title {
                font-size: 50px;
                line-height: 50px;
                padding-bottom: 30px;
            }

            .description {
                font-size: 20px;
                line-height: 25px;
                padding-bottom: 30px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .container-homepage {
        .img-background {
            height: 630px;
        }
        .intro-content-container {
            top: 45%;
            width: 600px;

            .title {
                font-size: 55px;
                line-height: 55px;
                padding-bottom: 35px;
            }

            .description {
                font-size: 17px;
                line-height: 24px;
                padding-bottom: 35px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .container-homepage {
        .img-background {
            height: 650px;
        }
        .intro-content-container {
            top: 50%;
            width: 600px;

            .title {
                font-size: 70px;
                line-height: 70px;
                padding-bottom: 35px;
            }

            .description {
                font-size: 18px;
                line-height: 26px;
                padding-bottom: 35px;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .container-homepage {
        .img-background {
            height: 790px;
        }
        .intro-content-container {
            top: 50%;
            width: 775px;

            .title {
                font-size: 75px;
                line-height: 100px;
                padding-bottom: 45px;
            }

            .description {
                font-size: 20px;
                line-height: 30px;
                padding-bottom: 45px;
            }
        }
    }
}
