.schedule-widget-container {
    margin-bottom: 150px;
    padding-top: 150px;
    .schedule-widget-title {
        text-align: center;
    }

    .schedule-widget-meniu {
        display: flex;
        justify-content: center;

        .schedule-widget-meniu-elem {
            cursor: pointer;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
    }

    .schedule-table {
        width: 90%;
        margin: auto;
        margin-top: 40px;

        .schedule-table-top-row {
            display: flex;
            text-align: center;
            font-size: 18px;

            .col {
                margin: 22px 0;
            }

            .day {
                width: 15%;
            }

            .time {
                width: 10%;
            }

            .location {
                width: 25%;
            }

            .match {
                width: 50%;
            }

            .group {
                width: 10%;
            }
        }

        .schedule-table-matches {
            margin-top: 20px;

            .shedule-match {
                display: flex;
                text-align: center;
                box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                margin-bottom: 20px;
                background-color: white;

                .col {
                    margin: 45px 0;
                    font-size: 20px;
                    height: 20px;
                }

                .day {
                    width: 15%;
                }

                .time {
                    width: 10%;
                }

                .location {
                    width: 25%;
                }

                .match {
                    width: 50%;
                    display: flex;
                    gap: 5%;
                    justify-content: center;
                    text-transform: uppercase;
                    .match-result {
                        font-size: 35px;
                        top: -10px;
                        position: relative;
                    }
                    .first-team,
                    .second-team {
                        display: flex;
                        .team-name {
                            position: relative;
                            top: 0px;
                        }
                        .team-logo {
                            width: 40px;
                            position: relative;
                            top: -13px;
                            padding: 0 15px;
                            width: auto;
                            height: 50px;
                        }
                    }
                }

                .group {
                    width: 10%;
                    border-radius: 100px;
                    text-align: center;
                    margin: 30px 0;
                    .group-circle {
                        border-radius: 50px;
                        width: 40px;
                        height: 40px;
                        margin: auto;
                        position: relative;
                        top: 6px;
                        .group-letter {
                            margin: auto;
                            text-align: center;
                            padding-top: 7px;
                        }
                    }
                }

                .after-group {
                    width: 10%;
                    margin: auto;
                    height: fit-content;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .schedule-widget-container {
        margin-bottom: 0px;
        margin-top: 0px;
        padding-top: 100px;

        .schedule-widget-title {
            font-size: 35px;
            margin-bottom: 20px;
        }

        .schedule-widget-meniu {
            gap: 22px;

            .schedule-widget-meniu-elem {
                font-size: 16px;
            }
        }

        .schedule-table {
            display: none;
        }

        .schedule-table-mobile {
            display: block;
            .schedule-match {
                width: 85%;
                margin: auto;
                padding: 4%;
                background-color: white;
                box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                margin-bottom: 20px;
                .group {
                    margin: auto;
                    font-size: 15px;
                    line-height: 25px;
                    text-align: center;
                    width: 80px;
                    border-radius: 30px;
                    margin-bottom: 20px;
                }

                .after-group {
                    width: fit-content;
                    line-height: 25px;
                    margin: auto;
                    font-size: 15px;
                    text-align: center;
                    border-radius: 30px;
                    margin-bottom: 20px;
                    padding: 0 15px;
                }
                .match-details {
                    display: flex;
                    justify-content: space-between;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    .match-result {
                        width: 20%;
                        font-size: 35px;
                        display: flex;
                        justify-content: center;
                    }
                    .first-team,
                    .second-team {
                        width: 38%;
                        display: flex;
                        .logo-container {
                            margin: auto;
                            .team-logo {
                                width: 30px;
                                padding: 0 5px 0 5px;
                                height: auto;
                            }
                        }

                        .team-name {
                            font-size: 12px;
                            margin: auto;
                        }
                    }
                    .first-team {
                        text-align: left;
                    }
                    .second-team {
                        text-align: right;
                    }
                }

                .match-info {
                    display: flex;
                    justify-content: space-evenly;
                    font-size: 14px;
                    .split-line {
                        border-left: 1px solid;
                        line-height: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 480px) {
    .schedule-widget-container {
        margin-bottom: 0px;
        padding-top: 100px;

        .schedule-widget-title {
            font-size: 32px;
            margin-bottom: 20px;
        }

        .schedule-widget-meniu {
            gap: 22px;

            .schedule-widget-meniu-elem {
                font-size: 20px;
            }
        }

        .schedule-table {
            display: none;
        }

        .schedule-table-mobile {
            .schedule-match {
                width: 85%;
                margin: auto;
                padding: 4%;
                background-color: white;
                box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                margin-bottom: 20px;
                .group {
                    margin: auto;
                    font-size: 15px;
                    line-height: 25px;
                    text-align: center;
                    width: 80px;
                    border-radius: 30px;
                    margin-bottom: 20px;
                }

                .after-group {
                    width: fit-content;
                    margin: auto;
                    font-size: 15px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 30px;
                    margin-bottom: 20px;
                    padding: 0 15px;
                }

                .match-details {
                    display: flex;
                    justify-content: center;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    .match-result {
                        font-size: 35px;
                        display: flex;
                        justify-content: center;
                    }
                    .first-team,
                    .second-team {
                        display: flex;
                        justify-content: space-around;
                        width: 40%;
                        .team-logo {
                            width: 40px;
                            padding: 0 10px 0 10px;
                            width: auto;
                            height: 50px;
                        }

                        .team-name {
                            font-size: 15px;
                        }
                    }
                    .first-team {
                        text-align: left;
                    }
                    .second-team {
                        text-align: right;
                    }
                }

                .match-info {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    margin-top: 20px;
                    .day {
                        width: 22%;
                    }
                    .time {
                        width: 15%;
                        text-align: center;
                    }
                    .location {
                        width: 50%;
                    }
                    .split-line {
                        border-left: 1px solid;
                        line-height: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .schedule-widget-container {
        margin-bottom: 60px;
        padding-top: 100px;

        .schedule-widget-title {
            font-size: 48px;
            margin-bottom: 20px;
        }

        .schedule-widget-meniu {
            gap: 22px;

            .schedule-widget-meniu-elem {
                font-size: 24px;
            }
        }

        .schedule-table {
            display: none;
        }

        .schedule-table-mobile {
            .schedule-match {
                width: 70%;
                margin: auto;
                padding: 4%;
                background-color: white;
                box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                margin-bottom: 20px;
                .group {
                    margin: auto;
                    font-size: 18px;
                    line-height: 32px;
                    text-align: center;
                    width: 80px;
                    border-radius: 30px;
                    margin-bottom: 20px;
                }

                .after-group {
                    width: fit-content;
                    margin: auto;
                    font-size: 18px;
                    line-height: 32px;
                    text-align: center;
                    border-radius: 30px;
                    margin-bottom: 20px;
                    padding: 0 15px;
                }

                .match-details {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    .match-result {
                        font-size: 35px;
                        display: flex;
                        justify-content: center;
                    }
                    .first-team,
                    .second-team {
                        width: 40%;
                        display: flex;
                        .team-logo {
                            width: 40px;
                            padding: 0 10px 0 10px;
                            width: auto;
                            height: 50px;
                        }

                        .team-name {
                            font-size: 18px;
                            position: relative;
                            top: 15px;
                        }
                    }
                }

                .match-info {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    .day {
                        width: 22%;
                    }
                    .time {
                        width: 15%;
                    }
                    
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .schedule-widget-container {
        padding-top: 120px;
        margin-bottom: 0px;
        .schedule-widget-title {
            font-size: 55px;
        }

        .schedule-widget-meniu {
            .schedule-widget-meniu-elem {
                font-size: 30px;
            }
        }

        .schedule-table-mobile {
            display: none;
        }
        .schedule-table {
            width: 90%;
            margin: auto;
            margin-top: 40px;
            display: block;
            .schedule-table-top-row {
                display: flex;
                text-align: center;
                font-size: 18px;

                .col {
                    margin: 22px 0;
                }

                .day {
                    width: 15%;
                }

                .time {
                    width: 10%;
                }

                .location {
                    width: 25%;
                }

                .match {
                    width: 50%;
                }

                .group {
                    width: 10%;
                }
            }

            .schedule-table-matches {
                margin-top: 20px;

                .shedule-match {
                    display: flex;
                    text-align: center;
                    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                    margin-bottom: 20px;
                    background-color: white;

                    .col {
                        margin: 45px 0;
                        font-size: 20px;
                        height: 20px;
                    }

                    .day {
                        width: 15%;
                    }

                    .time {
                        width: 10%;
                    }

                    .location {
                        width: 25%;
                    }

                    .match {
                        width: 50%;
                        display: flex;
                        gap: 5%;
                        justify-content: center;
                        text-transform: uppercase;
                        .match-result {
                            font-size: 35px;
                            top: -10px;
                            position: relative;
                        }
                        .first-team,
                        .second-team {
                            display: flex;
                            width: 44%;
                            .team-name {
                                font-size: 17px;
                                position: relative;
                                top: -10px;
                            }
                            .logo-container {
                                .team-logo {
                                    width: 40px;
                                    position: relative;
                                    top: -13px;
                                    padding: 0 15px;
                                    width: auto;
                                    height: 50px;
                                }
                            }
                        }
                        .first-team {
                            justify-content: space-between;
                        }
                        .second-team {
                            justify-content: space-between;
                        }
                    }

                    .group {
                        width: 10%;
                        border-radius: 100px;
                        text-align: center;
                        margin: 30px 0;
                        .group-circle {
                            border-radius: 50px;
                            width: 40px;
                            height: 40px;
                            margin: auto;
                            position: relative;
                            top: 6px;
                            .group-letter {
                                margin: auto;
                                text-align: center;
                                padding-top: 7px;
                            }
                        }
                    }
                    .after-group {
                        width: 10%;
                        margin: auto;
                        height: fit-content;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .schedule-widget-container {
        .schedule-widget-title {
            font-size: 65px;
            margin-bottom: 40px;
        }

        .schedule-widget-meniu {
            gap: 30px;

            .schedule-widget-meniu-elem {
                font-size: 35px;
            }
        }
        .schedule-table-mobile {
            display: none;
        }
    }

    .schedule-widget-container {
        .schedule-widget-title {
            text-align: center;
        }

        .schedule-widget-meniu {
            display: flex;
            justify-content: center;

            .schedule-widget-meniu-elem {
                cursor: pointer;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }

        .schedule-table {
            width: 90%;
            margin: auto;
            margin-top: 40px;

            .schedule-table-top-row {
                display: flex;
                text-align: center;
                font-size: 18px;

                .col {
                    margin: 22px 0;
                }

                .day {
                    width: 15%;
                }

                .time {
                    width: 10%;
                }

                .location {
                    width: 25%;
                }

                .match {
                    width: 50%;
                }

                .group {
                    width: 10%;
                }
            }

            .schedule-table-matches {
                margin-top: 20px;

                .shedule-match {
                    display: flex;
                    text-align: center;
                    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 3%);
                    margin-bottom: 20px;
                    background-color: white;

                    .col {
                        margin: 45px 0;
                        font-size: 20px;
                        height: 20px;
                    }

                    .day {
                        width: 15%;
                    }

                    .time {
                        width: 10%;
                    }

                    .location {
                        width: 25%;
                    }

                    .match {
                        display: flex;
                        gap: 5%;
                        justify-content: center;
                        text-transform: uppercase;
                        .match-result {
                            width: 10%;
                            font-size: 35px;
                            top: -10px;
                            position: relative;
                        }
                        .first-team,
                        .second-team {
                            display: flex;
                            width: 44%;
                            .team-name {
                                position: relative;
                                top: 0px;
                            }
                            .logo-container {
                                .team-logo {
                                    width: 40px;
                                    position: relative;
                                    top: -13px;
                                    padding: 0 15px;
                                    width: auto;
                                    height: 50px;
                                }
                            }
                        }
                        .first-team {
                            justify-content: space-between;
                        }
                        .second-team {
                            justify-content: space-between;
                        }
                    }

                    .group {
                        width: 10%;
                        border-radius: 100px;
                        text-align: center;
                        margin: 30px 0;
                        .group-circle {
                            border-radius: 50px;
                            width: 40px;
                            height: 40px;
                            margin: auto;
                            position: relative;
                            top: 6px;
                            .group-letter {
                                margin: auto;
                                text-align: center;
                                padding-top: 7px;
                            }
                        }
                    }
                    .after-group {
                        width: 10%;
                        margin: auto;
                        height: fit-content;
                    }
                }
            }
        }
    }
}
