@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"), url(./fonts/HelveticaNeue/HelveticaNeueLTProHvCn.woff) format("woff");
}
