$sub-color: black;
$main-color: #dee0de;

@mixin shrinkLabel {
  top: 10px;
  font-size: 12px;
  color: $main-color;
}

@mixin shrinkLabelMob {
  top: -12px !important;
  font-size: 10px !important;
  color: $main-color !important;
}

.group {
  position: relative;

  .form-input {
    font-family: "Helvetica Neue";
    background: none;
    color: black;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 25px 0;

    &:focus {
      outline: none;
    }

    &:focus ~ .form-input-label {
      @include shrinkLabel();
    }

  }

  input[type="password"] {
    letter-spacing: 0.3em;
  }
}

@media only screen and (min-width: 768px) {
  .half-width {
    width: 45%;
  }

  .third-width {
    width: 30%;
  }

  .full-width {
    width: 100%;
  }

  .form-input-label {
    color: $sub-color;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    top: 10px;
    transition: 300ms ease all;
  }

  .group {
    .form-input {
      font-size: 18px;
      font-family: "Helvetica Neue";
    }
  }
}

@media only screen and (max-width: 767px) {
  .half-width {
    width: 100%;
  }

  .third-width {
    width: 100%;
  }

  .full-width {
    width: 100%;
  }

  .form-input-label {
    color: black;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    top: -12px;
    transition: 300ms ease all;
  }

  .group {
    margin-bottom: 20px;
  }

  .form-input {
    font-family: "Helvetica Neue";
    padding-top: 5px !important;
    padding-bottom: 0 !important;
    border-bottom: 2px solid $sub-color !important;
    font-size: 14px;

    &:focus ~ .form-input-label {
      @include shrinkLabelMob();
    }
  }
}
