.registration-form-container {
    .form-title {
        font-weight: 700;
        text-align: center;
    }

    .form-sent-button {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 480px) {
    .registration-form-container {
        padding: 40px 40px;

        .form-title {
            font-size: 35px;
            margin-bottom: 32px;
        }

        .form-row {
            margin-bottom: 22px;

            .form-row-label {
                font-size: 16px;
                text-align: center;
            }

            .form-row-inputs {
                display: block;
                margin: 12px 0px;
            }
        }
    }
}

@media only screen and (min-width: 480px) {
    .registration-form-container {
        padding: 40px 15%;

        .form-title {
            font-size: 35px;
            margin-bottom: 34px;
        }

        .form-row {
            margin-bottom: 24px;

            .form-row-label {
                font-size: 19px;
            }

            .form-row-inputs {
                display: block;
                margin: 14px 0px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .registration-form-container {
        padding: 60px 15%;

        .form-title {
            font-size: 48px;
            margin-bottom: 36px;
        }

        .form-row {
            margin-bottom: 26px;

            .form-row-label {
                font-size: 21px;
            }

            .form-row-inputs {
                display: flex;
                justify-content: space-between;
                margin: 16px 0px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .registration-form-container {
        padding: 80px 20%;

        .form-title {
            font-size: 55px;
            margin-bottom: 38px;
        }

        .form-row {
            margin-bottom: 28px;

            .form-row-label {
                font-size: 23px;
            }

            .form-row-inputs {
                display: flex;
                justify-content: space-between;
                margin: 18px 0px;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .registration-form-container {
        padding: 100px 25%;
        margin-top: 100px;

        .form-title {
            font-size: 65px;
            margin-bottom: 40px;
        }

        .form-row {
            margin-bottom: 30px;

            .form-row-label {
                font-size: 25px;
            }

            .form-row-inputs {
                display: flex;
                justify-content: space-between;
                margin: 20px 0px;
            }
        }
    }
}
